<template>
  <a-table :columns="columns" :data-source="dataSource" bordered :loading="listLoading" class="components-table-demo-nested" rowKey="update_time" :pagination="false">
    <template v-slot:operation="{ record }"> 
      <a-button type="link" v-has:appAllPriceEdit="() => edit(record)" >修改</a-button>
    </template>
  </a-table>
  <a-modal v-model:visible="visible" title="平台费用" centered @ok="handleAdd" destroyOnClose>
    <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }" ref="formCell" :model="form" :rules="rules">
      <a-form-item label="模块:" >
         <a-input type="text" v-model:value="form.type_text" disabled/>
      </a-form-item>
      <a-form-item label="价格:" name="serve_price" >
        <a-input prefix="￥" suffix="RMB" v-model:value="form.serve_price" v-inputFilter:int />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
  import { $iscode } from "@/utils/app";
  import { ref, reactive, toRefs, inject, onMounted } from "vue";
  import { message } from "ant-design-vue";
  import {
    getappServeFees,
    uptappServeFees,
    brandAppAllPriceInfo,
    brandAppsServeUpdate
  } from "@/api/product";

  export default {
    name: "servesFree",
    setup(props) {
      const state = reactive({
        visible: false,
        form: {},
        listLoading: false,
        dataSource: [],
        columns: [
          {
            title: "功能模块",
            dataIndex: "type_text",
            key: "type_text",
          },
          {
            title: "价格",
            dataIndex: "serve_price",
            key: "serve_price",
          },
          {
            title: "操作",
            key: "operation",
            align:"center",
            slots: {
              customRender: "operation",
            },
            width:140
          },
        ],
        rules: {
          serve_price: [{ required: true, message: "请平台价格不能为空！" }],
        },
      });
      //页面加载列表数据
      const ongetList = async () => {
        state.dataSource.data = [];
        let res = await brandAppAllPriceInfo().then((res) => res.data).catch((error) => error);
        if($iscode(res)){
           state.dataSource = [res.data] || [];
           console.log(state.dataSource)
        }else{
           message.error(res.msg);
        }
      };

      
      // 新增或者编辑
      const formCell = ref();
      const handleAdd = function (e) {
        state.confirmLoading = true;
        formCell.value.validate().then((res) => {
              edit_model(state.form);
          }).catch(() => {
            message.error("请完善表单!");
          });
      };
      const edit = function (key) {
        // state.form = JSON.parse(JSON.stringify(key));
        state.form = JSON.parse(JSON.stringify(key))||{};
        state.visible = true;
      };

      // 修改
      const edit_model = async (data) => {
         const hide = message.loading('正在加载中...', 0);
        let res = await brandAppsServeUpdate({...state.form}).then((res) => res.data).catch((error) => error);
          setTimeout(hide);
        if ($iscode(res)) {
          state.visible = false;
          ongetList();
          formCell.value.resetFields();
           message.success(res.msg);
        }else{
          message.erro(res.msg);
        }
      };

      onMounted(()=>{
        ongetList();
      })
      return {
        ...toRefs(state),
        edit,
        handleAdd,
        formCell
      };
    },
  };
</script>